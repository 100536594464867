import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useSelector } from 'react-redux';
import './styles.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

const StudyTimeAllocationChart: React.FC = () => {
    const isDarkMode = useSelector((state: any) => state.settings.darkMode);

    const data = {
        labels: ["Series1", "series2", "Series3"],
        datasets: [
            {
                label: '# of Votes',
                data: [5, 12, 19],
                backgroundColor: [
                    '#EF884A',
                    '#FDBA74',
                    '#FFF7ED',
                ],
                borderColor: [
                    '#EF884A',
                    '#FDBA74',
                    '#FFF7ED',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        // maintainAspectRatio: true,
        plugins: {
            legend: {
                labels: {
                    color: isDarkMode ? '#F5F5F5' : '#101828',
                    boxWidth: 12,
                    boxHeight: 12,
                    padding: 10,
                },
                position: 'right' as const, // Can be 'top', 'left', 'right', 'bottom'
                align: 'center' as const,
            },
            title: {
                display: false,
                align: 'start' as const,
                text: 'Study Time Allocation',
                color: isDarkMode ? '#F5F5F5' : '#101828',
                font: {
                    size: 16,
                    weight: 'bold' as const,
                },
                padding: {
                    top: 10,
                    bottom: 30,
                },
            },
        },
    };

    return (
        <div className="time-allocation-chart">
            <p style={{ color: isDarkMode ? '#F5F5F5' : '#101828' }} className="time-allocation-title">Study Time Allocation</p>
            <div className="canvas-container">
                <Doughnut data={data} options={options} />
            </div>
        </div>
    );
};

export default StudyTimeAllocationChart;
