import React, { useEffect, useState } from "react";
import { ReactComponent as UploadCloud } from "../../assets/light/UploadCloud.svg"
import { ReactComponent as UploadCloudDark } from "../../assets/dark/UploadCloud.svg"
import { ReactComponent as TrashCan } from "../../assets/light/TrashCan.svg"
import "./styles.scss"
import { useSelector } from "react-redux";
// import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
// import { MdClear } from "react-icons/md";

interface IProps {
    onFilesSelected: (_: any) => void
    width: number | string;
    height: number | string;
    accept: string;
    innerFiles?: boolean
    files?: any;
    className?: string;
    multiple?: boolean;
}

const FileUpload: React.FC<IProps> = ({
    onFilesSelected,
    width,
    height,
    innerFiles = true,
    files: oldFiles,
    className,
    accept,
    multiple = false
}) => {
    const [files, setFiles] = useState<File[]>(oldFiles ?? []);
    const isDarkMode = useSelector((state: any) => state.settings.darkMode);

    const handleFileChange = (event: any) => {
        const selectedFiles = event.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            const newFiles = Array.from(selectedFiles);
            setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        }
    };
    const handleDrop = (event: any) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        if (droppedFiles.length > 0) {
            const newFiles = Array.from(droppedFiles);
            setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        }
    };

    const handleRemoveFile = (index: number) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    useEffect(() => {
        onFilesSelected(files);
    }, [files, onFilesSelected]);

    return (
        <section className={`drag-drop ${className || ""}`} style={{ width: width, height: height }}>
            <div
                className={`document-uploader ${files.length > 0 ? "upload-box active" : "upload-box"
                    }`}
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
            >
                <>
                    <div className="upload-info">
                        <input
                            type="file"
                            hidden
                            id="browse"
                            onChange={handleFileChange}
                            accept={accept}
                            multiple={multiple}
                        />
                        <label htmlFor="browse" className="browse-btn">
                            {isDarkMode ? <UploadCloudDark /> : <UploadCloud />}
                        </label>
                        <p className="header"><span className="header">Click to upload</span> <span className="hint">on drag and drop</span></p>
                        <p className="hint">
                            SVG, PNG, JPG or GIF (max. 800x400px)
                        </p>
                    </div>


                </>

                {files.length > 0 && innerFiles && (
                    <div className="file-list">
                        {files.map((file, index) => (
                            <div className="file-item" key={index}>
                                <div className="file-info">
                                    <p>{file.name}</p>
                                    {/* <p>{file.type}</p> */}
                                </div>
                                <div className="file-actions">
                                    <TrashCan onClick={() => handleRemoveFile(index)} />
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {files.length > 0 && innerFiles && (
                    <div className="success-file">
                        {/* <AiOutlineCheckCircle
              style={{ color: "#6DC24B", marginRight: 1 }}
            /> */}
                        <p>{files.length} file(s) selected</p>
                    </div>
                )}
            </div>
        </section>
    );
};

export default FileUpload;