import SideMenu from "../../components/sideMenu";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Logo from "../../assets/light/logo/logo.svg";
import DarkLogo from "../../assets/dark/logo/logo.svg";
import "./styles.scss";
import AppButton from "../../components/AppButton";
import TopBar from "../topBar";
import { useEffect, useState } from "react";
import Popup from "../../components/modal";
import { useSelector } from "react-redux";

const SideLayout = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:700px)");
  const [sideOpen, setSideOpen] = useState(false);
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);

  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );
  useEffect(() => {
    if (userDetail.onBoarding !== "completed") navigate("/onboarding");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail]);

  return (
    <Grid className={`main ${!matches ? "small-screen" : ""}`}>
      <Grid className="side-menus">
        <Box className="logo">
          <img
            src={isDarkMode ? DarkLogo : Logo}
            alt=""
            className="brand-logo"
          />
        </Box>
        <AppButton
          customVariant="analyse"
          label="Analyse Error Log"
          onClick={() => navigate("/analyser")}
        />
        <SideMenu />
      </Grid>
      <Grid className="right-container">
        <TopBar setSideOpen={setSideOpen} />
        <Grid className="content">
          <Outlet />
        </Grid>
      </Grid>
      <Popup
        open={sideOpen}
        setOpen={setSideOpen}
        position="left"
        width={250}
        height={"100%"}
      >
        <Grid className="side-menus">
          <Box className="logo">
            <img
              src={isDarkMode ? DarkLogo : Logo}
              alt=""
              className="brand-logo"
            />
          </Box>
          <AppButton
            customVariant="analyse"
            label="Analyse Error Log"
            onClick={() => {
              setSideOpen(false);
              navigate("/analyser");
            }}
          />
          <SideMenu onChoose={() => setSideOpen(false)} />
        </Grid>
      </Popup>
    </Grid>
  );
};

export default SideLayout;
