import React, { useState, useEffect, useMemo, memo } from 'react';

interface IProps {
  text?: string;
  speed?: number;
  children?: string;
  className?: string;
  index?: number;
  currIndex?: number;
  onComplete?: () => void;
}

const Typewriter: React.FC<IProps> = ({ text, speed = 10, children, className, index, currIndex, onComplete }) => {
  const [displayedText, setDisplayedText] = useState((index || index === 0) && (currIndex || currIndex === 0) && index < currIndex ? text || children : '');
  const [isTypingComplete, setIsTypingComplete] = useState((index || index === 0) && (currIndex || currIndex === 0) && index < currIndex ? true : false);
  const [status, setStatus] = useState((index || index === 0) && (currIndex || currIndex === 0) && index <= currIndex ? "processed" : "wait")
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rendered: string = useMemo(() => text || children || "", [])

  useEffect(() => {
    if (index === currIndex) {
      setStatus("processed")
      let ind = 0;
      const interval = setInterval(() => {
        setDisplayedText((prev) => rendered.slice(0, ind + 1));
        ind += 1;
        if (ind === rendered.length) {
          clearInterval(interval);
          setIsTypingComplete(true);  // Mark typing as complete
          onComplete && onComplete()
        }
      }, speed);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, speed, currIndex, index, status]);

  return status === "processed" ? (
    <p className={`typewriter ${isTypingComplete ? 'no-blink' : ''} ${className || ""}`}>
      {displayedText}
    </p>
  ) : <></>;
};

export default memo(Typewriter);
