import { Box } from "@mui/material";
import React from "react";
import GreetText from "../../GreetText";
import AppDatePicker from "../../DatePicker";
import AppButton from "../../AppButton";
import "./styles.scss";
import { Controller, useFormContext } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../../redux/Reducer/userReducer";

interface IProps {
  backClick: () => void;
}

const Stepper1: React.FC<IProps> = ({ backClick }) => {
  const dispatch = useDispatch();
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );
  const { setValue, getValues } = useFormContext();
  const { userName } = userDetail;

  const handleSubmit = () => {
    dispatch(
      updateUserData({
        examDate: getValues("examDate"),
      })
    );
  };

  return (
    <Box className="content-container">
      <Box className="top-area">
        <GreetText>{`Hello ${userName}!`}</GreetText>
        <div className="question-container">
          <p className="question">When is your G-MAT Exam date?</p>
          <p className="hint">
            Here is supposed to state the reason why we need this copy! For
            efficiency.
          </p>
        </div>
        <Controller
          name="gmatDate"
          rules={{
            required: "This field must be required",
            validate: (value) =>
              !dayjs(value)?.isValid() ? "Date is not valid" : true,
          }}
          render={({
            field: { value, onChange, ...rest },
            fieldState: { error },
          }) => {
            const changeHandleer = (e: Dayjs | null) => {
              setValue("examDate", e?.utc()?.format() || null);
              onChange(e?.utc()?.format() || null);
            };
            const newValue = value ? dayjs(value) : null;
            return (
              <AppDatePicker
                preLabel="Date"
                className="onboarding-date"
                errorMessage={error?.message}
                onChange={(e) => changeHandleer(e)}
                value={newValue}
                disablePast={true}
                {...rest}
              />
            );
          }}
        />
      </Box>
      <Box className="bottom-buttons">
        <AppButton
          className="continue-button"
          onClick={backClick}
          customVariant="back"
          label="Back"
        />
        <AppButton
          className="continue-button"
          type="submit"
          onClick={handleSubmit}
          label="Continue"
        />
      </Box>
    </Box>
  );
};

export default Stepper1;
