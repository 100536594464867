import React, { useEffect } from "react";
import BrandLogo from "../../assets/light/logo/logo.svg";
import DarkBrandLogo from "../../assets/dark/logo/logo.svg";
import { ReactComponent as ThemeLight } from "../../assets/light/ThemeChange.svg"
import { ReactComponent as ThemeDark } from "../../assets/dark/Sun.svg"
import { ReactComponent as Logout } from "../../assets/light/Logout.svg"
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { settings } from "../../redux/Reducer/settingsReducer";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { logout } from "../../redux/Reducer/userReducer";

interface IProps {
    auth: boolean;
}

const HeaderLayout: React.FC<IProps> = ({ auth }) => {
    const dispatch = useDispatch();
    const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);
    useEffect(() => {
        const root = document.documentElement;
        if (isDarkMode) {
            root.classList.add("dark-mode");
        } else {
            root.classList.remove("dark-mode");
        }
    }, [isDarkMode]);

    const ThemeDarkEdit = () => <div className="iconfit"><ThemeDark /></div>

    return (
        <Box className={`all-container`}>
            <div className={!auth ? "authenticated" : ""}>
                <div className="header-layout">
                    <img
                        src={isDarkMode ? DarkBrandLogo : BrandLogo}
                        alt=""
                        className="brand-logo"
                    />
                    <Box className="icon-container" >
                        <div className="theme-switch" onClick={() => dispatch(settings({ darkMode: !isDarkMode }))}>{isDarkMode ? <ThemeDarkEdit /> : <ThemeLight />}</div>
                        {!auth && <Logout className="logout-icon" onClick={() => dispatch(logout())}/>}
                    </Box>
                </div>
                <Outlet />
            </div>
        </Box >
    );
};

export default HeaderLayout;
