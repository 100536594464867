import { Calendar, CalendarProps, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react';
import { Box } from '@mui/system';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import "./styles.scss"

const localizer = dayjsLocalizer(dayjs)
interface IProps extends Omit<CalendarProps, "localizer"> {
    height: number | string;
    width: number | string;
    eventList?: any[];
    className?: string
}

const MyCalendar: React.FC<IProps> = ({ height, width, eventList = [], className, ...props }) => {
    const [colors, setColors] = useState<Record<string, any>>({})
    const [activeView, setActiveView] = useState("month")
    const [currentDate, setCurrentDate] = useState(new Date());

    const fixedColors = [
        { lightShade: "#FFF7ED", darkShade: "#EF884A" },
        { lightShade: "#EEF2FF", darkShade: "#6366F1" },
        { lightShade: "#F0FDFA", darkShade: "#134E4A" },
    ]


    const Card = (title: string, color: any) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    padding: "6px 10px",
                    borderRadius: "5px",
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: "500",
                    backgroundColor: color?.lightShade,
                    position: "relative",
                    filter: "brightness(1)",
                }}
            >
                <span
                    style={{
                        content: '""',
                        position: "absolute",
                        left: "0px",
                        height: "60%",
                        width: "2px",
                        backgroundColor: color?.darkShade,
                        marginLeft: "5px",
                        borderRadius: "2px"
                    }}
                />
                <div
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        flexGrow: 1,
                        color: color?.darkShade
                    }}
                >
                    {title}
                </div>
            </div>
        );
    };

    const CustomToolbar = (toolbar: any) => {
        const goToBack = () => {
            toolbar.onNavigate('PREV');
        };

        const goToNext = () => {
            toolbar.onNavigate('NEXT');
        };

        const goToToday = () => {
            toolbar.onNavigate('TODAY');
        };

        const changeview = (view: string) => {
            toolbar.onView(view);
            setActiveView(view);
        }

        return (
            <Box className="custom-toolbar">
                <Box className="btn-group">
                    <button className='arrow' onClick={goToBack}><NavigateBeforeIcon /></button>
                    <button onClick={goToToday}>{new Date(currentDate).toLocaleString('default', { month: 'long' })}</button>
                    <button className='arrow' onClick={goToNext}><NavigateNextIcon /></button>
                </Box>
                <Box className="btn-group">
                    <button className={activeView === "month" ? "rbc-active" : ""} onClick={() => changeview("month")}>Month</button>
                    <button className={activeView === "week" ? "rbc-active" : ""} onClick={() => changeview("week")}>Week</button>
                    <button className={activeView === "agenda" ? "rbc-active" : ""} onClick={() => changeview("agenda")}>Agenda</button>
                </Box>
            </Box>
        );
    };

    const updatedList = useMemo(() => {
        const temp = { ...colors }
        const data = eventList.map((x, index) => {
            if (typeof x.title === "string") {
                x.showable = x.title;
                if (!temp[x.title]) {
                    temp[x.title] = fixedColors[(Object.keys(temp).length) % 3];
                }
                x.title = Card(x.title, temp[x.title])
            };
            return x;
        })
        setColors(temp)
        return data
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventList])


    return (
        <div className='app-calendar-container'>
            <Calendar
                {...props}
                className={`app-calendar ${className || ""}`}
                views={{
                    month: true,
                    week: true,
                    agenda: true,
                }}
                formats={{
                    monthHeaderFormat: (date) => date.toLocaleString('default', { month: 'long' }),
                    dayHeaderFormat: (date) => date.toLocaleString('default', { month: 'long' }),
                    weekdayFormat: (date) => date.toLocaleString('default', { month: 'long' })
                }}
                components={{
                    toolbar: CustomToolbar,
                }}
                defaultView='month'
                popup={false}
                events={updatedList}
                startAccessor="start"
                endAccessor="end"
                style={{ height, width }}
                localizer={localizer}
                tooltipAccessor={(ele: any) => ele?.showable}
                onNavigate={(date) => setCurrentDate(date)}
            />
        </div>
    )
}
export default MyCalendar;