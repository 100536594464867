import { Notification } from "../../utils/types";

export const mockData = {
  analysisReport: {
    title: "GMAT Preparation: The Good, The Bad, and The Hilarious",
    message:
      "Welcome to the whimsical world of GMAT preparation! Buckle up as we dive into your practice log data, where numbers meet humor and insights lead to success Let's see how you're faring on this rollercoaster ride of reasoning and problem-solving!",
    subject: "GMAT Practice Log Analysis",
    performance: {
      "Data Insights - Data Sufficiency": {
        label: "Data Sufficiency",
        value: "7866%",
        comment:
          "You're sufficing just enough data to keep the GMAT gods happy A little more clarity could boost your score!",
      },
      "Data Insights - Graphs and Tables": {
        label: "Graphs and Tables",
        value: "100%",
        comment:
          "Graphs and tables are your playground! You’ve hit a perfect score—time to throw a party!",
      },
      "Data Insights - Two-Part Analysis": {
        label: "Two-Part Analysis",
        value: "9032%",
        comment:
          "Two-part analysis? More like two-part victory! You're acing this section with style",
      },
      "Verbal Reasoning - Critical Reasoning": {
        label: "Critical Reasoning",
        value: "7619%",
        comment:
          "Critical reasoning is your Achilles' heel, but fear not! With a bit of focus, you'll turn that frown upside down",
      },
      "Data Insights - Multi-Source Reasoning": {
        label: "Multi-Source Reasoning",
        value: "50%",
        comment:
          "Multi-source reasoning is like a puzzle you haven't quite solved yet Let’s work on piecing it together!",
      },
      "Verbal Reasoning - Sentence Correction": {
        label: "Sentence Correction",
        value: "7921%",
        comment:
          "You're almost a grammar guru! Just a sprinkle of extra practice and you'll be correcting sentences like a pro",
      },
      "Quantitative Reasoning - Problem Solving": {
        label: "Problem Solving",
        value: "7917%",
        comment:
          "You're solving problems like a math magician! Keep pulling those rabbits out of hats",
      },
      "Verbal Reasoning - Reading Comprehension": {
        label: "Reading Comprehension",
        value: "8054%",
        comment:
          "Your reading skills are sharp! Just a few more passages and you'll be the next literary legend",
      },
    },
    studyRecommendation: {
      "Time Management Mastery": {
        title: "Time Management: The Clock is Ticking!",
        message:
          "Your average times are looking good, but a little more speed in critical reasoning could work wonders Practice with a timer to keep those pesky seconds in check!",
        subject: "All Sections",
      },
      "Data Sufficiency Practice": {
        title: "Data Sufficiency: The Art of Enough",
        message:
          "You're doing well, but a few more practice questions could help you reach the zenith of sufficiency Try to visualize the data and break down the problems step by step!",
        subject: "Data Insights",
      },
      "Focus on Critical Reasoning": {
        title: "Critical Reasoning: The Quest for Clarity",
        message:
          "Your critical reasoning score is a tad shy Consider dedicating some time to practice with sample questions and review the explanations Remember, every great detective needs to sharpen their reasoning skills!",
        subject: "Verbal Reasoning",
      },
    },
  },
};

export const mockNotifications: Notification[] = [
  {
    id: "1",
    title: "Hey It's study time!",
    timestamp: "Thursday 3:15pm",
    subject: "Combinations and Factoria",
    subjectCategory: "Statistics & Probability",
    description:
      "This study was recommended due to the error bla bla bla and how you can manage the questions and how you study it again",
    questionCount: 20,
    seen: false,
  },
  {
    id: "2",
    title: "Hey It's study time!",
    timestamp: "Thursday 4:30pm",
    subject: "Linear Algebra",
    subjectCategory: "Mathematics",
    description:
      "Brush up on your matrix operations and vector spaces Brush up on your matrix operations and vector spaces ",
    questionCount: 15,
    seen: false,
  },
  {
    id: "3",
    title: "Hey It's study time!",
    timestamp: "Thursday 4:30pm",
    subject: "Linear Algebra",
    subjectCategory: "Mathematics",
    description:
      "Brush up on your matrix operations and vector spaces Brush up on your matrix operations and vector spaces ",
    questionCount: 15,
    seen: false,
  },
];
