import { Box } from "@mui/material";
import { ReactComponent as File } from "../../../assets/light/Notepad.svg";
import "./styles.scss";
import React from "react";
import { useSelector } from "react-redux";

const ErrorAnalysed = () => {
  const chartDetails = useSelector((state: any) => state?.chartData);
  return (
    <Box className="error-logs-analyzed">
      <Box className="text-content">
        <p className="title">TOTAL ERROR LOGS ANALYSED</p>
        <p className="value">{chartDetails?.total}</p>
      </Box>
      <Box className="svg-container">
        <File />
      </Box>
    </Box>
  );
};

export default ErrorAnalysed;
