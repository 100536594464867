import React, { forwardRef, ReactElement } from "react";
import { TextField, Box, TextFieldVariants, TextFieldProps, InputAdornment } from "@mui/material";
import "./styles.scss";

type IProps = {
  errorMessage?: string;
  preLabel?: string;
  className?: string;
  hint?: string;
  variant?: TextFieldVariants;
  icon?: {
    position: "start" | "end";
    icon: ReactElement;
    onClick?: () => void;
  };
} & TextFieldProps;

const TextBox= forwardRef<HTMLInputElement, IProps>(
  (
    { icon, preLabel, errorMessage, className, variant = "outlined",hint, ...prop }, ref,
  ) => {
    return (
      <Box className="text-field">
        {!!preLabel && <div className="label">{preLabel}</div>}
        <TextField
          ref={ref}
          {...prop}
          error={!!errorMessage}
          variant={variant}
          fullWidth
          InputProps={{
            sx: {
              "&::placeholder": {
                color: "#6c757d", // Adjust placeholder text color if needed
                opacity: 1, // Ensures the placeholder text is fully opaque
              },
            },
            ...(icon
              ? {
                ...(icon?.position === "start"
                  ? {
                    startAdornment: (
                      <InputAdornment position={icon.position} onClick={() => icon?.onClick && icon.onClick()} >
                        {icon.icon}
                      </InputAdornment>
                    ),
                  }
                  : {
                    endAdornment: (
                      <InputAdornment position={icon.position} onClick={() => icon?.onClick && icon.onClick()}>
                        {icon.icon}
                      </InputAdornment>
                    ),
                  }),
              }
              : {})
          }}
          className={`field ${className || ""}`}
          sx={{
            marginBottom: "16px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#e0e0e0", // Adjust the border color
              },
              "&:hover fieldset": {
                borderColor: "#bdbdbd", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#3f51b5", // Border color when focused
              },
            },
            "& .MuiInputLabel-root": {
              color: "#6c757d", // Label color
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#3f51b5", // Label color when focused
            },
          }}
        />
        {errorMessage ? <div className="error-msg">{errorMessage}</div> : <></>}
        {(hint && !errorMessage) && <div className="hint">{hint}</div>}
      </Box>
    );
  })

export default TextBox;
