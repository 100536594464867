import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import "./styles.scss"

const marks = [
  { value: 525, label: 'GOOD' },
  // { value: 555, label: 'VERY GOOD' },
  { value: 585, label: 'AVERAGE' },
  { value: 645, label: 'EXPERT' },
  { value: 685, label: 'EXPERT+' },
  { value: 805, label: '' }, // Empty label for the last point
];

interface IProps {
  value: number
  onChange: (_: any) => void
}

const RatingComponent: React.FC<IProps> = ({ value, onChange }) => {
  const [positions, setPositions] = useState<any[]>([])
  const [retrigger, setRetrigger] = useState<boolean>(true)
  const [width, setWidth] = useState<number>(0)
  const [colors] = useState(["red", "orange", "yellow", "green"])
  const sliderRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (!retrigger) {
      setRetrigger(true)
    }
  }, [retrigger])

  useEffect(() => {
    setWidth(sliderRef.current?.offsetWidth || 0)
  }, [sliderRef.current?.offsetWidth])


  return (
    <Box className="rating-component-container">
      {retrigger && <Slider
        // track={false}
        ref={sliderRef}
        aria-labelledby="track-false-slider"
        value={value}
        marks={marks}
        step={10}
        min={marks[0].value}
        max={marks[marks.length - 1].value}
        onChange={(e: any) => {
          onChange(e.target.value)
        }}
        slots={{
          rail: (e) => {
            return <div id='slider-rail' className="slider-rail"></div>
          },
          track: (e) => {
            const totalWidth: number | undefined = width || undefined;
            const latestIndex = positions.findIndex((ele) => parseFloat(e.style.width) <= ele)
            return <div className="slider-track" style={{ ...e.style }}>
              {colors.map((each: string, ind: number) => {
                return (
                  <div
                    key={ind} style={{
                      // Convert colors[latestIndex - 1] to each to have each colors on each box
                      backgroundColor: colors[latestIndex - 1],
                      minWidth: `${((((positions[ind + 1] - positions[ind])) / 100) * (totalWidth || 1)) + 0.5}px`,
                      borderRadius: "6px 6px 0 0"
                    }}></div>
                )
              })}
            </div>
          },
          markLabel: (e) => {
            const index = e["data-index"];
            if (!positions.length) setRetrigger(false)
            setPositions(prev => {
              prev[index] = parseFloat(e.style.left);
              return prev
            })
            return <div
              className='range-mark-label'
              style={{
                left: `${(positions[index] + positions[index + 1]) / 2}%`,
                position: "absolute",
                transform: "translateX(-50%)",
                color: "#6B7280",
                width: `${(positions[index + 1] - positions[index]) + 0.5}%`,
                textAlign: "center"

              }}>{e.children}</div>
          },
          mark: (e) => {
            const index = e["data-index"];
            return (
              <div className="each-mark" style={{ ...e.style }}>
                <div className='MuiSlider-mark' style={{ ...e.style }}></div>
                <div className='mui-mark-label'>{e.ownerState?.marks[index]?.value}</div>
              </div>
            )
          },
        }}
      />}
    </Box>
  );
}

export default RatingComponent;