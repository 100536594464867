import React, { memo, useState } from "react";
import { Button, Typography, Box, Divider, Link } from "@mui/material";
import GLogo from "../../assets/light/logo/google_logo.svg";
import "./styles.scss";
import TextBox from "../TextBox";
import AppButton from "../AppButton";
import { Controller } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const SignupForm: React.FC<any> = (props) => {
  const { handleGoogleLogin, loading } = props;
  const [visibility, setVisibility] = useState({
    pass: false,
    confirmPass: false,
  });

  return (
    <Box
      className="box-container"
      sx={{
        width: 360,
        margin: "auto",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          width: "100%"
        }}
      >
        <h4 className="sign-in-header" style={{ margin: 0, marginBottom: "20px" }}>Let's Get Started</h4>
      </Box>

      <Button
        className="google-login-button"
        variant="contained"
        sx={{
          textTransform: "none",
          borderColor: "#dadce0",
          color: "#3c4043",
          width: "100%",
          padding: "8px 15px",
          fontWeight: 500,
          fontSize: "14px",
          borderRadius: "8px",
          "&:hover": {
            borderColor: "#dadce0",
            backgroundColor: "#f8f9fa",
          },
        }}
        onClick={handleGoogleLogin}
      >
        <Box
          component="img"
          src={GLogo}
          alt="Google logo"
          sx={{
            height: 20,
            width: 20,
            marginRight: "8px",
          }}
        />
        <div className="label">Sign up with Google</div>
      </Button>

      <Divider className="divider" sx={{ width: "100%", marginY: "16px" }}>
        Or
      </Divider>

      <Box className="text-field-container">
        <Controller
          name="name"
          rules={{
            required: "name is required for signup"
          }}
          render={({ field: { value, ...rest }, fieldState: { error } }) =>
            <TextBox
              preLabel="Name"
              placeholder={"Enter your name"}
              errorMessage={error?.message}
              value={value}
              {...rest}
            />
          } />
        <Controller
          name="email"
          rules={{
            required: "Email is required for signup",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Enter a valid email"
            }
          }}
          render={({ field: { value, ...rest }, fieldState: { error } }) =>
            <TextBox
              preLabel="Email"
              placeholder={"Enter your email"}
              errorMessage={error?.message}
              value={value}
              {...rest}
            />
          } />
        <Controller
          name="password"
          rules={{
            required: "Password is required for signup",
            pattern: {
              value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              message: "passsword is invalid"
            }
          }}
          render={({ field: { value, ...rest }, fieldState: { error } }) =>
            <TextBox
              preLabel="Password"
              placeholder={"Create a password"}
              hint={"Must be at least 8 characters."}
              errorMessage={error?.message}
              type={visibility.pass ? "text" : "password"}
              icon={{
                position: "end",
                icon: !visibility.pass ? <VisibilityIcon className="cursor-point" /> : <VisibilityOffIcon className="cursor-point" />,
                onClick: () => {
                  setVisibility((prev) => ({ ...prev, pass: !prev.pass }));
                },
              }}
              value={value}
              {...rest}
            />
          } />


      </Box>

      <AppButton loading={loading} label="Sign Up" type="submit" className="login-btn" />

      <Typography variant="body2" sx={{ marginTop: "16px" }}>
        Already have an account?{" "}
        <Link href="sign-in" underline="none">
          Log in
        </Link>
      </Typography>
    </Box>
  );
};

export default memo(SignupForm);
