import { Box, Divider } from "@mui/material";
import { useCallback, useState } from "react";
import AppAvatar from "../../components/Avatar";
import "./styles.scss";
import AppTabs from "../../components/Tabs";
import Examination from "../../components/profiletabs/examination";
import Notification from "../../components/profiletabs/notification";
import Password from "../../components/profiletabs/password";
import { useSelector } from "react-redux";
import { dateFormatter } from "../../utils/helper";
import axios from "axios";
import AppButton from "../../components/AppButton";

const Profile = () => {
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );
  const [loading, setLoading] = useState(false)

  const [activeTab, setActiveTab] = useState("exams");
  const tabsList = [
    { label: "Exams Settings", value: "exams" },
    { label: "Notifications", value: "notification" },
    // { label: "Password", value: "password" },
  ];

  const SelectedContainer = useCallback(() => {
    let children = <></>;
    switch (activeTab) {
      case "exams":
        children = <Examination />;
        break;
      case "notification":
        children = <Notification />;
        break;
      case "password":
        children = <Password />;
        break;

      default:
        break;
    }
    return <Box className="profile-content-container">{children}</Box>;
  }, [activeTab]);


  const triggerPasswordReset = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "POST",
        url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        headers: {
          "content-type": "application/json",
        },
        data: {
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          email: userDetail.email,
          connection: "staging-pocketbud", // Your connection name (e.g., database)
        },
      });
      console.log("Password reset email sent:", response.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error(
        "Error triggering password reset:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <Box className="profile-container">
      <Box className="left-container">
        <Box className="avatar-container">
          <AppAvatar name={userDetail?.nickName} className="profile-avatar" />
          <Box className="profile-details">
            <p className="username">{userDetail?.nickName}</p>
            <p className="useremail">{userDetail?.email}</p>
          </Box>
        </Box>
        <Box className="basic-details-container">
          <p className="header-text">Basic details</p>
          <Divider sx={{ width: "100%" }} />
          <Box className="basic-details">
            <p className="head">Name</p>
            <p className="data">{userDetail?.email}</p>
          </Box>
          <Box className="basic-details">
            <p className="head">Exam date</p>
            <p className="data">{dateFormatter(userDetail?.examDate, "MMMM DD, YYYY")}</p>
          </Box>
          <Divider sx={{ width: "100%" }} />
          <AppButton
            label="Reset Password"
            onClick={triggerPasswordReset}
            className="save-btn"
            loading={loading}
          />
        </Box>
      </Box>
      <Box className="tabs-container">
        <AppTabs
          value={activeTab}
          onChange={(_, val) => {
            setActiveTab(val);
          }}
          tabsList={tabsList}
          className="profile-tabs"
        />
        <SelectedContainer />
      </Box>
    </Box>
  );
};

export default Profile;
