import { Box, Link } from '@mui/material'
import React, { memo } from 'react'
import AppButton from '../AppButton';
import TextBox from '../TextBox';
import { Controller } from 'react-hook-form';
import "./styles.scss"

interface IProps {
    loading: boolean;
}

const ForgotPassword: React.FC<IProps> = ({ loading }) => {

    return (
        <Box className="forgot-password-container"
            sx={{
                width: 360,
                margin: "auto",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
            }}>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                }}
            >
                <h4 className="sign-in-header">Forgot Password</h4>
            </Box>
            <p className="reset-hint"> Enter the email address associated with your account. We will send You the reset link to your mail</p>
            <Box className="text-field-container">
                <Controller
                    name="email"
                    rules={{
                        required: "Email is required for signin",
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Enter a valid email"
                        }
                    }}
                    render={({ field, fieldState: { error } }) =>
                        <TextBox
                            // preLabel="Email"
                            placeholder={"Enter your email"}
                            errorMessage={error?.message}
                            {...field}
                        />
                    }
                />
                <Box className="forgot-cont">
                    <Link className="forgot-pass" href="sign-in" underline="none">
                      Go to Sign in
                    </Link>
                  </Box>
            </Box>
            <AppButton loading={loading} label="Send mail" type="submit" className="login-btn" />
        </Box>
    )
}

export default memo(ForgotPassword)