import "./styles.scss";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Dashboard from "../assets/light/menuIcons/Dashboard.svg"
import DashboardActive from "../assets/light/menuIcons/DashboardActive.svg"
import StudyPlan from "../assets/light/menuIcons/StudyPlan.svg"
import StudyPlanActive from "../assets/light/menuIcons/StudyPlanActive.svg"
import { useSelector } from "react-redux";
import { Icon } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

type sideMenuItemProps = {
  name: string;
  navigationLink: string;
  icon: any;
  activeIcon: any;
  darkActiveIcon: any;
  darkIcon: any;
  disabled?: boolean;
  onChoose?: () => void
};

interface IProps {
  onChoose?: () => void
}

const SideMenuItem: React.FC<sideMenuItemProps> = ({
  name,
  navigationLink,
  icon,
  activeIcon,
  darkActiveIcon,
  darkIcon,
  disabled,
  onChoose
}) => {
  const location = useLocation()
  const [activeMenuStatus, setActiveMenuStatus] = useState<boolean>(location.pathname === navigationLink);
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);

  useEffect(() => {
    setActiveMenuStatus(location.pathname === navigationLink)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])


  return (
    <>
      {disabled ? (
        <div className={"navigation-link locked-sidemenu"} onClick={onChoose}>
          <div className="sidemenu-item locked-sidemenu">
            {/* <img
              className="menu-img"
              alt=""
              src={isDarkMode ? darkIcon : icon}
            /> */}
            <Icon
              component={LockOutlinedIcon}
              className={
                !isDarkMode ? "lock-icon-side-menu" : "lock-icon-side-menu-dark"
              }
            />
            <h1>{name}</h1>
          </div>
        </div>
      ) : (
        <NavLink
          onClick={onChoose}
          to={navigationLink}
          className={({ isActive }) => {
            return isActive ? "navigation-link active" : "navigation-link";
          }}
        >
          {activeMenuStatus ? (
            <div className="sidemenu-item-active">
              <div className="sub-menu-left">
                <img
                  className="menu-img"
                  alt=""
                  src={isDarkMode ? darkActiveIcon : activeIcon}
                />
                <h1>{name}</h1>
              </div>
            </div>
          ) : (
            <div className="sidemenu-item">
              <img
                className="menu-img"
                alt=""
                src={isDarkMode ? darkIcon : icon}
              />
              <h1>{name}</h1>
            </div>
          )}
        </NavLink>
      )}
    </>
  );
};

const sideMenuData = [{
  icon: Dashboard,
  darkIcon: Dashboard,
  darkActiveIcon: DashboardActive,
  name: "Dashboard",
  navigationLink: "/dashboard",
  activeIcon: DashboardActive,
  disbled: false
}, {
  darkIcon: StudyPlan,
  darkActiveIcon: StudyPlanActive,
  name: "Adaptive Study Plan",
  navigationLink: "/studyPlan",
  icon: StudyPlan,
  activeIcon: StudyPlanActive,
  disabled: false,
  // },{
  //   darkIcon: DarkReceiptIcon,
  //   darkActiveIcon: DarkReceiptActiveIcon,
  //   name: "Data Insights",
  //   navigationLink: "/data-insights",
  //   icon: ReceiptIcon,
  //   activeIcon: ReceiptActiveIcon,
}]

const SideMenu: React.FC<IProps> = ({ onChoose }) => {
  return (
    <div className={"sidemenu"}>
      <div className="side-menu">
        <div className="sidemenu-items">
          {sideMenuData.map((ele, index) => (
            <SideMenuItem
              key={index}
              icon={ele.icon}
              darkIcon={ele.darkIcon}
              darkActiveIcon={ele.darkActiveIcon}
              name={ele.name}
              navigationLink={ele.navigationLink}
              activeIcon={ele.activeIcon}
              disabled={ele.disabled}
              onChoose={onChoose}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
