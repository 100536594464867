import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./styles.scss";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TimeManagementChart: React.FC = () => {
  const tmtData = useSelector((state: any) => state?.chartData?.tmt);
  const isDarkMode = useSelector((state: any) => state.settings.darkMode);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "top" as const,
        align: "end" as const,
        labels: {
          color: isDarkMode ? "#F5F5F5" : "#101828",
          boxWidth: 10,
          boxHeight: 10,
          padding: 10,
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: false,
        text: "Section-wise Time Management Trend",
        align: "start" as const,
        color: isDarkMode ? "#F5F5F5" : "#101828",
        font: {
          size: 16,
          weight: "bold" as const,
        },
        padding: {
          top: 10,
          bottom: 20,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: isDarkMode ? "#A3A3A3" : "#4B5563",
          // padding: 20,
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Week",
          color: isDarkMode ? "#A3A3A3" : "#4B5563",
          font: {
            size: 14,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          color: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "#E5E7EB",
        },
        ticks: {
          color: isDarkMode ? "#A3A3A3" : "#4B5563",
          stepSize: 20,
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Time Per Question",
          color: isDarkMode ? "#A3A3A3" : "#4B5563",
          font: {
            size: 14,
          },
        },
      },
    },
    elements: {
      point: {
        radius: 3,
        pointStyle: false as const,
      },
      line: {
        borderWidth: 2,
      },
    },
  };

  return (
    <div className="time-management-chart">
      <p style={{ color: isDarkMode ? '#F5F5F5' : '#101828' }} className="time-allocation-title">Section-wise Time Management Trend</p>
      <div className="canvas-container">
        <Line data={tmtData} options={options} />
      </div>
    </div>
  );
};

export default TimeManagementChart;
