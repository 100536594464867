import * as React from 'react';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import "./styles.scss"

interface IProps extends TabsProps {
    tabsList: { label: string, value: string | number }[];
    className?: string;
}
const AppTabs: React.FC<IProps> = ({ className, tabsList, ...rest }) => {


    return (
        <Box sx={{ width: '100%' }}>
            <Tabs className={`app-tabs ${className || ""}`} {...rest}>
                {tabsList.map((tab, index: number) => (
                    <Tab className='each-tab' key={index} label={tab.label} value={tab.value} />
                ))}
            </Tabs>
        </Box>
    );
}

export default AppTabs;