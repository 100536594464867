import { Box } from '@mui/material';
import React from 'react'
import { ReactComponent as Book } from "../../assets/light/Book.svg"
import "./styles.scss"
import AppButton from '../../components/AppButton';
import MyCalendar from '../../components/Calendar';


interface IProps {
    data?: any;
}
interface Event {
    allDay?: boolean | undefined;
    title?: React.ReactNode | undefined;
    start?: Date | undefined;
    end?: Date | undefined;
    resource?: any;
}

const mockEvents: Event[] = [{
    allDay: false,
    title: "Scrum",
    start: new Date("Thu, 12 Sep 2024 05:30:00 GMT"),
    end: new Date("Thu, 12 Sep 2024 06:30:00 GMT")
}, {
    allDay: false,
    title: "Scrum for pocketBud",
    start: new Date("Thu, 12 Sep 2024 07:30:00 GMT"),
    end: new Date("Thu, 13 Sep 2024 08:30:00 GMT")
}, {
    allDay: true,
    title: "Scrum for pocketBud",
    start: new Date("Thu, 13 Sep 2024 12:30:00 GMT"),
    end: new Date("Thu, 13 Sep 2024 13:30:00 GMT")
}, {
    allDay: false,
    title: "Sample Event",
    start: new Date("Thu, 13 Sep 2024 01:30:00 GMT"),
    end: new Date("Thu, 15 Sep 2024 02:30:00 GMT")
}, {
    allDay: false,
    title: "Sample Event",
    start: new Date("Thu, 13 Sep 2024 01:30:00 GMT"),
    end: new Date("Thu, 13 Sep 2024 02:30:00 GMT")
}]

const StudyPlan: React.FC<IProps> = ({ data }) => {
    return (
        <Box className="study-plan-container">
            <Box className="study-plan-view-container">
                <Box className="file-container">
                    <Box className="left-space">
                        <Box className="icon-container">
                            <Book />
                        </Box>
                        <div className="file-details">
                            <p className="file-content">NO OF COURSE SECTIONS</p>
                            <p className="file-content bold">{10}</p>
                        </div>
                    </Box>
                    <AppButton label="View Sections" variant='outlined' customVariant='custom-outlined' />
                </Box>

                <Box className="calendar-container">
                    <MyCalendar height={"100%"} width={"100%"} eventList={mockEvents} />
                </Box>
            </Box>
        </Box>
    )
}

export default StudyPlan