import { chunk } from './mapper';
import { SubscribeService } from '../services/subscribe.service';
import { insertIntoErrorLogTable } from './query';
import { LocalDB } from './sql';
import { v4 as uuidv4 } from "uuid";
import { DateFormat } from './types';

const subscribeApi = new SubscribeService()

export const regSw = async () => {
    if ('serviceWorker' in navigator) {
        let url = process.env.PUBLIC_URL + '/sw.js';
        const reg = await navigator.serviceWorker.register(url, { scope: '/' });
        return reg;
    }
    throw Error('serviceworker not supported');
}

export const subscribe = async (sub: any) => {
    try {
        await subscribeApi.subscribe(sub)
    } catch (error) {
        console.log("Error", error)
    }
}

export const sendErrorDataToBackend = async (errorData: any, ErrorLogApis: any) => {
    const jobId = uuidv4()
    try {
        const chuk = chunk(errorData, 200);
        await Promise.all(
            chuk.map(async (x) => {
                await ErrorLogApis.SaveErrorLog(
                    x.map((x) => ({ ...x, jobId }))
                );
            })
        );
        await ErrorLogApis.StartSummary(
            jobId
        );
    } catch (error: any) {
        console.log("error", error.message);
    }
    return jobId
};

export const storeDataInLocalSQL = async (data: any[]) => {
    const chunks = chunk(data, 500);
    let i = 0
    for (const chuk of chunks) {
        const query = insertIntoErrorLogTable();
        const insertStmt = LocalDB?.prepare(query);
        // eslint-disable-next-line no-loop-func
        chuk?.forEach((row) => {
            const value = [
                row?.id || i,
                row?.error_template_id,
                row?.section || "",
                row?.category || "",
                row?.subcategories || "",
                row?.question_type || "",
                row?.topic_names || "",
                row?.subtopic_names || "",
                row?.brief_question_text || "",
                row?.link_to_question || "",
                row?.bookmarked || false,
                row?.solution || "",
                row?.guessed || false,
                row?.selected_option || "",
                row?.date_attempted || "",
                row?.time_taken || "",
                row?.performance || false,
                row?.difficulty || 0,
                row?.question_source || "",
                row?.careless_mistake || false,
                row?.anxiety || 0,
                row?.conceptual_gap || false,
                row?.time_mismanagement || false,
                row?.vocabulary_void || false,
                row?.comprehension_error || false,
                row?.description || "",
                row?.learnings || "",
                row?.notes || "",
                row?.question_id || "",
                row?.user_id || "",
            ];
            insertStmt?.run(value);
            i += 1;
        });
        insertStmt?.free();
    }
};

export const getErrorLogDataFromBackend = async (getIdTokenClaims: any, dispatch: any, chartData: any, chartDatas: any, isAuthenticated: any, ErrorLogApis: any) => {
    try {

        let hasMoreData = true;

        while (hasMoreData) {
            const resUserData = await ErrorLogApis.GetErrorLog();
            if (resUserData?.status === "success") {
                storeDataInLocalSQL(resUserData?.data);
                dispatch(
                    chartData({ ...chartDatas, json: resUserData?.data, isAuthenticated })
                );
            }
        }

    } catch (error: any) {
        console.log("error", error.message);
    }
};

export const dateFormatter = (dateStr: Date, format: DateFormat): string => {
    if (!dateStr) return ""
    const date = new Date(dateStr)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const shortMonths = months.map(month => month.slice(0, 3));

    switch (format) {
        case 'YYYY-MM-DD':
            return `${year}-${month}-${day}`;
        case 'DD-MM-YYYY':
            return `${day}-${month}-${year}`;
        case 'MM/DD/YYYY':
            return `${month}/${day}/${year}`;
        case 'MMM DD, YYYY':
            return `${shortMonths[date.getMonth()]} ${day}, ${year}`;
        case 'MMMM DD, YYYY':
            return `${months[date.getMonth()]} ${day}, ${year}`;
        default:
            throw new Error('Unsupported date format');
    }
};