import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Box } from "@mui/material";
import AppAvatar from "../../Avatar";
import AppButton from "../../AppButton";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../../redux/Reducer/userReducer";
import SequentialTypeWriter from "../../Typewriter/sequential";
// import { UserService } from "../../../services/user.service";

interface IProps {
  onNext: () => void;
}

const Welcome: React.FC<IProps> = ({ onNext }) => {
  // const userService = new UserService();
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );
  const [nameValue, setNameValue] = useState<string>(
    userDetail?.nickName || ""
  );
  const [gotName, setGotName] = useState<boolean>(false);
  const divRef = useRef<null | HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    setNameValue(target.innerText);
    if (gotName) {
      dispatch(updateUserData({ nickName: nameValue }));
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior of adding a new line
      setGotName(true);
      dispatch(updateUserData({ nickName: nameValue }));
    }
  };

  // Function to place caret at the end of the content
  const placeCaretAtEnd = (el: HTMLDivElement) => {
    if (document.createRange) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(el);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.focus();
      placeCaretAtEnd(divRef.current); // Place caret at the end on mount
    }
  }, []);

  useEffect(() => {
    if (divRef.current) {
      placeCaretAtEnd(divRef.current); // Place caret at the end after every update
    }
  }, [nameValue]);

  const handleClick = async () => {
    // await userService.UpdateUser({
    //   ...userDetail,
    //   ...{
    //     nickName: nameValue,
    //     onBoarding: 1,
    //   },
    // });
    onNext();
  };

  const [index, setIndex] = useState(0);

  const Typewriter = SequentialTypeWriter({ index, setIndex });

  return (
    <Box className="welcome-container">
      <Box className="question-container">
        <Typewriter index={0} className="question">
          Hello I'm PiBi
        </Typewriter>
        <Typewriter index={1} className="hint">
          I'm a next generation AI assistant, here to help you ace your
          competitive exams.
        </Typewriter>
        <Typewriter index={2} className="hint">
          Together, we'll cover important topics, review your progress, and
          ensure you're fully prepared.
        </Typewriter>
        <Typewriter index={3} className="hint">
          Let’s get started and make your study journey both effective and fun!
        </Typewriter>
        <Typewriter index={4} className="hint">
          I'd love for us to get to know each other a bit better.
        </Typewriter>
      </Box>
      <Box className="name-container">
        <AppAvatar name="Unknown user" />
        <Box className="input-container">
          <Typewriter index={5} className="greet">
            Nice to meet you, I'm
          </Typewriter>
          {index > 5 && (
            <div
              ref={divRef}
              className="input-name"
              contentEditable
              suppressContentEditableWarning={true}
              onInput={handleInput}
              onKeyDown={handleKeyDown}
            >
              {nameValue}
            </div>
          )}
        </Box>
      </Box>
      {gotName && (
        <Box className="question-container">
          <Typewriter
            index={6}
            className="question"
          >{`Lovely to meet you ${nameValue}`}</Typewriter>
          <Typewriter index={7} className="hint">
            Few things to know before we starting together
          </Typewriter>
        </Box>
      )}
      {gotName && (
        <AppButton
          className="welcome-btn"
          label="Acknowledge & Continue"
          onClick={() => {
            handleClick();
          }}
        />
      )}
    </Box>
  );
};

export default Welcome;
