import initSqlJs, { Database } from 'sql.js';

export let LocalDB: Database

export const initializeDatabase = async () => {
    const SQL = await initSqlJs({ locateFile: file => `/sql-wasm.wasm` });
    LocalDB = new SQL.Database();
    LocalDB.run('CREATE TABLE error_logs (id serial4 NOT NULL,error_template_id varchar(255) NOT NULL,"section" varchar(255) NULL,category varchar(255) NULL,subcategories varchar(255) NULL,question_type varchar(255) NULL,topic_names varchar(255) NULL,subtopic_names varchar(255) NULL,brief_question_text text NULL,link_to_question varchar(255) NULL,bookmarked bool NULL,guessed bool NULL,date_attempted date NULL,time_taken varchar(255) NULL,performance bool NULL,difficulty varchar(255) NULL,question_source text NULL,careless_mistake bool NULL,anxiety int4 NULL,conceptual_gap bool NULL,time_mismanagement bool NULL,vocabulary_void bool NULL,comprehension_error bool NULL,description text NULL,learnings text NULL,notes text NULL,question_id varchar(255) NULL,user_id varchar(255) NULL,created_at timestamp DEFAULT CURRENT_TIMESTAMP NULL,solution varchar NULL,selected_option varchar NULL)');

    // // Load data from local storage
    // const data = JSON.parse(localStorage.getItem('myData'));

    // // Create a table and insert data

    // const insertStmt = LocalDB.prepare('INSERT INTO myTable VALUES (?, ?, ?);');

    // data.forEach(row => {
    //     insertStmt.run([row.id, row.name, row.age]);
    // });

    // insertStmt.free();
    // return LocalDB;
}