import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./styles.scss";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PerformanceChart: React.FC = () => {
  const performanceData = useSelector(
    (state: any) => state?.chartData?.performance
  );
  const isDarkMode = useSelector((state: any) => state.settings.darkMode);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        align: "start" as const,
        text: "Performance By Section",
        padding: {
          top: 10,
          bottom: 30,
        },
        color: isDarkMode ? "#F5F5F5" : "#101828",
        font: {
          size: 16,
          weight: "bold" as const,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: isDarkMode ? "#A3A3A3" : "#4B5563",
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          color: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "#E5E7EB",
          drawBorder: false,
        },
        ticks: {
          color: isDarkMode ? "#A3A3A3" : "#4B5563",
          beginAtZero: true,
          max: 80,

          stepSize: 20,
        },
      },
    },
  };

  return (
    <div className="performance-chart">
      <p style={{ color: isDarkMode ? '#F5F5F5' : '#101828' }} className="time-allocation-title">Performance By Section</p>
      <div className="canvas-container">
        <Bar data={performanceData} options={options} />
      </div>
    </div>
  );
};

export default PerformanceChart;
