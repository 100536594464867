/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import GreetText from "../../GreetText";
import AppButton from "../../AppButton";
import TextBox from "../../TextBox";
import RatingComponent from "../../RatingComponent";
import { Controller, useFormContext } from "react-hook-form";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../../redux/Reducer/userReducer";

interface IProps {
  backClick: () => void;
}

const Stepper2: React.FC<IProps> = ({ backClick }) => {
  const [modified, setModified] = useState(false);
  const dispatch = useDispatch();
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );
  const { userName } = userDetail;
  const { watch, setValue, getValues } = useFormContext();

  const validData = useMemo(() => {
    return (
      watch("gmatScore") && watch("gmatScore") > 524 && watch("gmatScore") < 806
    );
  }, [watch("gmatScore")]);

  useEffect(() => {
    setValue("gmatScore", 525);
  }, []);

  const handleSubmit = () => {
    dispatch(
      updateUserData({
        exceptedGmatScore: getValues("gmatScore"),
      })
    );
  };

  const toOddMultipleOfFive = (num: number): number => {
    let nearestMultipleOfFive = Math.round(num / 5) * 5;
    if (nearestMultipleOfFive % 2 === 0) {
      nearestMultipleOfFive += 5;
    }
    return nearestMultipleOfFive;
  }

  return (
    <Box className="content-container">
      <Box className="top-area">
        <GreetText>{`Hello ${userName}!`}</GreetText>
        <Box className="question-container">
          <p className="question">What is Your target G-Mat score?</p>
          <p className="hint">
            Here is supposed to state the reason why we need this copy! For
            efficiency.
          </p>
        </Box>
        <Box className="input-container">
          <Controller
            name="gmatScore"
            render={({ field: { value, onChange, ...rest } }) => {
              const handleChange = (e: any) => {
                const regex = /^\d+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  if (!modified) setModified(true);
                  onChange(e.target.value);
                }
              };

              return (
                <TextBox
                  className="mark-text"
                  value={value === undefined ? 525 : value}
                  onChange={handleChange}
                  {...rest}
                  onBlur={() => onChange(toOddMultipleOfFive(value))}
                />
              );
            }}
          />
          <RatingComponent
            value={Number(watch("gmatScore")) || 0}
            onChange={(e: number) => {
              if (!modified) setModified(true);
              setValue("gmatScore", Number(e));
            }}
          />
          <p
            className={`motivation ${
              (watch("gmatScore") < 586 && modified) || !validData
                ? "active"
                : ""
            } ${!validData ? "error-cls" : ""}`}
          >
            {!validData
              ? "Enter a valid score"
              : "Don't You have confidence on you buddy 💪😎"}
          </p>
        </Box>
      </Box>
      <Box className="bottom-buttons">
        <AppButton
          className="continue-button"
          onClick={backClick}
          customVariant="back"
          label="Back"
        />
        <AppButton
          className="continue-button"
          type="submit"
          onClick={handleSubmit}
          label="Continue"
        />
      </Box>
    </Box>
  );
};

export default Stepper2;
