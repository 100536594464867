import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { ReactComponent as Svg1 } from "../../assets/light/logo/logo_t1.svg";
import { ReactComponent as Svg2 } from "../../assets/light/logo/logo_t2.svg";
import { ReactComponent as Svg3 } from "../../assets/light/logo/logo_t3.svg";
import { ReactComponent as Svg4 } from "../../assets/light/logo/logo_t4.svg";
import { ReactComponent as DarkSvg1 } from "../../assets/dark/logo/logo_t1.svg";
import { ReactComponent as DarkSvg2 } from "../../assets/dark/logo/logo_t2.svg";
import { ReactComponent as DarkSvg3 } from "../../assets/dark/logo/logo_t3.svg";
import { ReactComponent as DarkSvg4 } from "../../assets/dark/logo/logo_t4.svg";
import { LinearProgress } from "@mui/material";
import "./styles.scss"

type LoaderProps = {
  open: boolean;
  progress?: boolean;
  progressPercent?: number;

};

export const Loader: React.FC<LoaderProps> = ({ open, progress = false, progressPercent = 0 }) => {
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);
  const intervalref = useRef<null | NodeJS.Timer>(null)
  const [currentSvgIndex, setCurrentSvgIndex] = useState(0);
  const svgs = [
    { light: <Svg1 />, dark: <DarkSvg1 />, interval: 500 },
    { light: <Svg2 />, dark: <DarkSvg2 />, interval: 750 },
    { light: <Svg1 />, dark: <DarkSvg1 />, interval: 500 },
    { light: <Svg3 />, dark: <DarkSvg3 />, interval: 750 },
    { light: <Svg1 />, dark: <DarkSvg1 />, interval: 500 },
    { light: <Svg4 />, dark: <DarkSvg4 />, interval: 750 },
  ];

  useEffect(() => {
    if (open) {
      intervalref.current = setInterval(() => {
        setCurrentSvgIndex((prevIndex) => (prevIndex + 1) % svgs.length);
      }, svgs[(currentSvgIndex + 1) % svgs.length].interval);
    } else {
      clearInterval(intervalref.current as NodeJS.Timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div style={{ display: open ? "block" : "none" }} className="loader-container">
      <div
        className="logo-container"
        style={{
          color: isDarkMode ? "#FCAC63" : "#74BAD3",
        }}
      >
        {svgs[currentSvgIndex][isDarkMode ? "dark" : "light"]}
        <div className="progress-div">
          {progress && (<LinearProgress variant="determinate" value={progressPercent} />)}
        </div>
      </div>
    </div>
  );
};
