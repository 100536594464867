import { Popover, PopoverProps } from '@mui/material';
import React, { ReactElement } from 'react';

interface IProps extends PopoverProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    anchorEl: HTMLElement | null;
    children: ReactElement;
    className?: string;
}

const AppPopover: React.FC<IProps> = ({
    className,
    open,
    setOpen,
    anchorEl,
    children,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'right',
    },
    ...popoverProps }) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            {...popoverProps}
            className={`app-popover ${className || ""}`}
        >
            {children}
        </Popover>
    );
};

export default AppPopover;
