/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import Greeting from "../../components/GreetText/greeting";
import "./styles.scss";
import ExamCountdown from "../../components/dashboard/examdate";
import PerformanceChart from "../../components/dashboard/performanceChart";
import AccuracyRadarChart from "../../components/dashboard/accuracyRadar";
import TimeManagementChart from "../../components/dashboard/timeManagementChart";
import StudyTimeAllocationChart from "../../components/dashboard/studyTimeAllocation";
import ErrorAnalysed from "../../components/dashboard/errorAnalysed";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components/Loader";
import { RootState } from "../../redux/Reducer";
import { useEffect } from "react";
import { ErrorLogService } from "../../services/errorLog.service";
import { resetChart } from "../../redux/Reducer/chartDataReducer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const errorService = new ErrorLogService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );
  const chartDetails = useSelector((state: any) => state?.chartData);

  const { hasMoreData, lastPageDetails } = useSelector(
    (state: RootState) => state?.chartData
  );

  const checkNewData = async () => {
    try {
      const resData = await errorService.GetErrorLog(lastPageDetails.page, lastPageDetails.pageSize);
      if (resData?.data?.length !== lastPageDetails?.dataLength) {
        dispatch(resetChart())
      }
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (!hasMoreData) checkNewData()
  }, [])

  useEffect(() => {
    if (!chartDetails?.total && !hasMoreData) {
      toast.error("You didn't uploaded any data to see the details")
      navigate("/analyser")
    }
  }, [hasMoreData])



  return (
    <Box className="dashboard-container" sx={hasMoreData ? {
      height: "100% !important",
      width: "100% !important",
      overflow: "hidden !important"
    } : {}}>
      <Greeting />
      <Box className="chart-container">
        <ErrorAnalysed />
        <Box className="grid-container-1">
          <AccuracyRadarChart />
          <ExamCountdown examDate={userDetail.examDate} />
          <PerformanceChart />
        </Box>
        <Box className="grid-container-2">
          <StudyTimeAllocationChart />
          <TimeManagementChart />
        </Box>
      </Box>
      <Loader open={hasMoreData} />
    </Box>
  );
};

export default Dashboard;
