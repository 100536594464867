import React, { useEffect } from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./pages/route";
import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "./components/Loader";
import { initializeDatabase } from "./utils/sql";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Toaster from "./components/Toaster";
import useSocket from "./hooks/useSocket";

const App: React.FC = () => {
  const { isLoading } = useAuth0();
  const isDarkMode = useSelector((state: any) => state.settings.darkMode);
  const isApiLoading = useSelector((state: any) => state.settings.isLoading);

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
    typography: {
      allVariants: {
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          '"Roboto"',
          '"Oxygen"',
          '"Ubuntu"',
          '"Cantarell"',
          '"Fira Sans"',
          '"Droid Sans"',
          '"Helvetica Neue"',
          "sans-serif",
        ].join(","),
        textTransform: "none",
        fontSize: 14,
      },
    },
  });

  useSocket();

  useEffect(() => {
    initializeDatabase();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        {isLoading || isApiLoading ? (
          <div
            style={{
              width: "100dvw",
              height: "100dvh",
              zIndex: 999,
              backgroundColor: isDarkMode ? "#121212" : "#EFEDEC",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader open={isLoading || isApiLoading} />
          </div>
        ) : (
          <Router>
            <Routes>
              <Route path="/*" element={<AppRoutes />} />
            </Routes>
          </Router>
        )}
        <Toaster />
      </ThemeProvider>
    </>
  );
};

export default App;
