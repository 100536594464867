import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import "./styles.scss";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface AccuracyRadarChartProps { }

const AccuracyRadarChart: React.FC<AccuracyRadarChartProps> = () => {
  const avgAccuracyData = useSelector(
    (state: any) => state?.chartData?.avgAccuracy
  );
  const isDarkMode = useSelector((state: any) => state.settings.darkMode);

  const options: ChartOptions<"radar"> = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      r: {
        alignToPixels: true,
        angleLines: {
          color: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
        },
        grid: {
          color: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
        },
        pointLabels: {
          color: isDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)",
          padding(ctx) {
            if (ctx.index === 0) return 10;
            return -10;
          },
          font: {
            size: 10,
          },
        },
        ticks: {
          color: isDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)",
          backdropColor: "transparent",
          stepSize: 20,
          font: {
            size: 10,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        align: "center",
        labels: {
          color: isDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)",
          font: {
            size: 12,
          },
          boxWidth: 10,
          boxHeight: 10,
          padding: 10,
          borderRadius: 2,
          useBorderRadius: true,
        },
      },
      title: {
        display: false,
        align: "start" as const,
        text: "Avg. Accuracy by Question Type",
        color: isDarkMode ? "#F5F5F5" : "#101828",
        font: {
          size: 16,
          weight: "bold" as const,
        },
        padding: {
          top: 10,
          bottom: 10,
        },
      },
    },
    elements: {
      point: {
        pointStyle: false,
      },
    },
  };

  return (
    <div
      className={`accuracy-radar-chart ${isDarkMode ? "dark-mode" : "light-mode"
        }`}
    >
      <p style={{ color: isDarkMode ? '#F5F5F5' : '#101828' }} className="time-allocation-title">Avg. Accuracy by Question Type</p>
      <div className="canvas-container">
        <Radar data={avgAccuracyData} options={options} />
      </div>
    </div>
  );
};

export default AccuracyRadarChart;
