import React, { useEffect, useRef } from 'react';
import { ReactComponent as OpenBook } from "../../assets/light/OpenBook.svg"
import { Box, Divider, Tooltip } from '@mui/material';
import "./styles.scss"
import AppButton from '../AppButton';
import { Notification } from '../../utils/types';

const NotificationItem: React.FC<{ notification: Notification }> = ({ notification }) => (
    <div className="notification-item">
        <div className="notification-header">
            <OpenBook />
            <Box className="right-content" >
                <h3>{notification.title}</h3>
                <span>{notification.timestamp}</span>
                <div className="notification-content">
                    <Box className="text-block">
                        <Box className="title-content">
                            <h4>{notification.subject}</h4>
                            <div className="question-count"><span className="count">{notification.questioncount}</span> Questions</div>
                        </Box>
                        <span className="subject-category">{notification.subjectcategory}</span>
                        <Tooltip title={notification.message}>
                            <p>{notification.message}</p>
                        </Tooltip>
                    </Box>
                    <AppButton label='Study Now' sx={{ width: "fit-content", margin: "10px" }} />
                </div>
            </Box>
        </div>
    </div>
);

interface IProps {
    notifications: Notification[]
    setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>
}

const NotificationsComponent: React.FC<IProps> = ({ notifications, setNotifications }) => {

    const notificationRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const index = parseInt(entry.target.getAttribute('data-id') || '0', 10);
                if (entry.isIntersecting) {
                    // Mark the notification as seen when it comes into view
                    setNotifications((prevNotifications) =>
                        prevNotifications.map((notif, i) =>
                            i === index ? { ...notif, seen: true } : notif
                        )
                    );
                }
            });
        });

        notificationRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className="notifications-popover">
            <h2 className='not-header'>Notifications</h2>
            <Divider className="divider" sx={{ width: "100%" }} />
            <div className="notifications-list">
                {notifications.length ? notifications.map((notification, index) => (
                    <div
                        key={notification.id}
                        data-id={index} // Unique identifier for each notification
                        ref={(el) => (notificationRefs.current[index] = el)}>
                        <NotificationItem key={notification.id} notification={notification} />
                    </div>
                )) : <p className="no-content">No new notifications for you</p> }
            </div>
        </Box>
    );
};

export default NotificationsComponent;