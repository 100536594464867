import React, { useState, useEffect } from 'react';
import './styles.scss';
import dayjs from 'dayjs';
import AppDatePicker from '../../DatePicker';

interface ExamCountdownProps {
  examDate: string;
}

const ExamCountdown: React.FC<ExamCountdownProps> = ({ examDate }) => {
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const calculateDaysLeft = () => {
      const now = new Date();
      const examDay = new Date(examDate);
      const timeDiff = examDay.getTime() - now.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      setDaysLeft(daysDiff);
    };

    calculateDaysLeft();
    const timer = setInterval(calculateDaysLeft, 86400000); // Update every 24 hours

    return () => clearInterval(timer);
  }, [examDate]);

  const percentComplete = ((21 - daysLeft) / 21) * 100;

  return (
    <div className="exam-countdown">
      <div className="exam-date">
        <p className='title'>My exam date</p>
        <AppDatePicker value={dayjs(examDate)} disabled={true} />
      </div>
      <div className="countdown-circle">
        <svg viewBox="0 0 36 36" className="circular-chart">
          <path
            className="circle-bg"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle"
            strokeDasharray={`${percentComplete}, 100`}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="20.35" className="percentage">{daysLeft}</text>
          <text x="18" y="24.35" className="days-text">days to go</text>
        </svg>
      </div>
    </div>
  );
};

export default ExamCountdown;