import { Avatar } from "@mui/material";
import React from "react";

interface IProps {
  name: string;
  onClick?: (_: any) => void;
  className?: string;
}

const AppAvatar: React.FC<IProps> = ({ name, onClick, className }) => {
  if (!name) name = "";
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    if (name.split(" ")?.length > 1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${
          name.split(" ")[1][0]
        }`.toUpperCase(),
      };
    } else {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}`.toUpperCase(),
      };
    }
  };

  return (
    <Avatar className={className} {...stringAvatar(name)} onClick={onClick} />
  );
};

export default AppAvatar;
