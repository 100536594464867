import { useState } from "react";
import Typewriter from ".";

interface TypewriterProps {
  text?: string;
  speed?: number;
  children?: string;
  className?: string;
  index: number;
  currIndex?: number;
  onComplete?: () => void;
}

const SequentialTypeWriter = (props?: any) => {
  const [index, setIndex] = useState(0);
  return (args: TypewriterProps) => {
    return (
      <Typewriter
        {...args}
        onComplete={() => {
          if (props?.setIndex) {
            props?.setIndex((prev: any) => prev + 1);
          } else {
            setIndex((prev) => prev + 1);
          }
        }}
        currIndex={props?.index ? props?.index : index}
      />
    );
  };
};

export default SequentialTypeWriter;
