
import React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { CircularProgress } from '@mui/material';
import "./styles.scss"

interface IProps extends SwitchProps {
    className?: string;
    loading?: boolean;
}

const CustomSwitch = styled(({ className, loading, ...props }: IProps) => (
    <Switch className={`app-toggle ${className}`} disabled={loading && !props.checked} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? 'var(--prime-color)' : 'var(--prime-color)',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: 'var(--prime-color)',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const AppToggle: React.FC<IProps> = ({ loading, ...props }) => {
    return <CustomSwitch {...props}
        loading={loading}
        icon={
            <React.Fragment>
                <span className="MuiSwitch-thumb" >
                    {loading && (
                        <CircularProgress
                            className='switch-loader'
                            size={16}
                            thickness={4}
                            sx={{
                                color: 'var(--prime-color)',
                            }}
                        />
                    )}
                </span>
            </React.Fragment>
        }
    />
}

export default AppToggle;