import React from 'react'
import "./styles.scss"

interface IProps {
    text?: string
    children?: string
}

const GreetText: React.FC<IProps> = ({ text, children}) => {
    return (
        <p className='greeting-text'>{text || children}</p>
    )
}

export default GreetText