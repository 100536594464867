import { useEffect, useState } from "react";
import AppToggle from "../../Toggle";
import { Box, Divider } from "@mui/material";
import { subscribe } from "../../../utils/helper";
import "./styles.scss";

const Notification = () => {
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [loading, setLoading] = useState(false)
  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration | null>(null);

  useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((reg) => {
          setRegistration(reg);
        })
        .catch((err) =>
          console.error("Service Worker registration failed:", err)
        );
    }
  }, []);
  useEffect(() => {
    if (registration)
      registration.pushManager.getSubscription().then((sub) => {
        if (sub) {
          console.log("sub", sub);
          setIsSubscribed(true);
        }
      });
  }, [registration]);

  const subscribeUser = async (): Promise<void> => {
    setLoading(true)
    try {
      if (registration) {
        const sub = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            "BJRLVUkr-9HTM-md5GHl4b2JJ4zQaY3KaYQvL2g6unTgBcWYfisWCwjMR3VV98podzqlphfnySoUl3Ct2PbHFnA",
        });
        setIsSubscribed(true);
        console.log("User is subscribed:", sub);
        // Send the subscription to your server
        await subscribe(sub);
      }
    } catch (err) {
      console.error("Failed to subscribe the user: ", err);
    }
    setLoading(false)
  };

  const unsubscribeUser = async (): Promise<void> => {
    try {
      if (registration) {
        const sub = await registration.pushManager.getSubscription();
        if (sub) {
          await sub.unsubscribe();
        }
        setIsSubscribed(false);
        console.log("User is unsubscribed");
        // Remove the subscription from your server
        await removeSubscriptionFromServer(sub);
      }
    } catch (err) {
      console.error("Error unsubscribing", err);
    }
  };

  const removeSubscriptionFromServer = async (
    sub: PushSubscription | null
  ): Promise<void> => {
    // Implement the logic to remove the subscription from your server
  };

  return (
    <Box className="notification-container">
      <Box className="top-box">
        <Box className="question-container">
          <Box className="text-container">
            <p className="question">Proposed Exam date?</p>
            <p className="answer">
              Here is supposed to state the reason why we need this copy! For
              efficiency.
            </p>
          </Box>
          <AppToggle
            loading={loading}
            checked={isSubscribed}
            onChange={(e) => {
              console.log("changed")
              if (e.target?.checked) {
                subscribeUser();
              } else unsubscribeUser();
            }}
          />
        </Box>
        <Divider
          className="divider"
          sx={{ width: "100%", marginY: "20px" }}
        ></Divider>
      </Box>
      {/* <Box className="btn-container">
        <AppButton label="Save Changes" className="save-btn" />
      </Box> */}
    </Box>
  );
};

export default Notification;
