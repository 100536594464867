/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import React, { useMemo } from "react";
import GreetText from "../../GreetText";
import AppButton from "../../AppButton";
import "./styles.scss";
import TextBox from "../../TextBox";
import RatingComponent from "../../RatingComponent";
import { Controller, useFormContext } from "react-hook-form";
import Checkbox from "../../Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../../redux/Reducer/userReducer";
import { UserService } from "../../../services/user.service";
import { loader } from "../../../redux/Reducer/settingsReducer";

interface IProps {
  backClick: () => void;
}

const Stepper3: React.FC<IProps> = ({ backClick }) => {
  const userService = new UserService();
  const { setValue, watch, getValues } = useFormContext();
  const dispatch = useDispatch();
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );
  const { userName } = userDetail;
  const validData = useMemo(() => {
    return (
      (watch("currGmatScore") &&
        watch("currGmatScore") > 524 &&
        watch("currGmatScore") < 806) ||
      watch("currGmatScore") === undefined
    );
  }, [watch("currGmatScore")]);

  const getUserDetails = async () => {
    const data = await userService.GetUser();
    if (!!data.data) dispatch(updateUserData(data.data));
  };

  const handleSubmit = async () => {
    dispatch(loader({ isLoading: true }));
    dispatch(
      updateUserData({
        currentGmatScore: getValues("currGmatScore"),
        attendedGmatBefore: !getValues("attendedGmatBefore"),
      })
    );
    await userService.UpdateUser({
      ...userDetail,
      ...{
        currentGmatScore: getValues("currGmatScore"),
        attendedGmatBefore: !getValues("attendedGmatBefore"),
        onBoarding: "completed",
      },
    });
    await getUserDetails();
    dispatch(loader({ isLoading: false }));
  };

  const toOddMultipleOfFive = (num: number): number => {
    let nearestMultipleOfFive = Math.round(num / 5) * 5;
    if (nearestMultipleOfFive % 2 === 0) {
      nearestMultipleOfFive += 5;
    }
    return nearestMultipleOfFive;
  };

  return (
    <Box className="content-container">
      <Box className="top-area">
        <GreetText>{`Hello ${userName}!`}</GreetText>
        <Box className="question-container">
          <p className="question">What is Your current G-Mat score?</p>
          <p className="hint">
            Here is supposed to state the reason why we need this copy! For
            efficiency.
          </p>
          <Box className="old-status">
            <Controller
              name="attendedGmatBefore"
              render={({ field: { value, onChange, ...rest } }) => (
                <Checkbox
                  value={value || false}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  {...rest}
                />
              )}
            />
            <span>I haven’t taken the G-MAT Test Yet</span>
          </Box>
        </Box>
        {!watch("attendedGmatBefore") && (
          <Box className="input-container">
            <Controller
              name="currGmatScore"
              render={({ field: { value, onChange, ...rest } }) => {
                const handleChange = (e: any) => {
                  const regex = /^\d+$/;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    onChange(e.target.value);
                  }
                };

                return (
                  <TextBox
                    className="mark-text"
                    value={value === undefined ? 525 : value}
                    onChange={handleChange}
                    {...rest}
                    onBlur={() => onChange(toOddMultipleOfFive(value))}
                  />
                );
              }}
            />
            <RatingComponent
              value={Number(watch("currGmatScore") || 0)}
              onChange={(e: number) => setValue("currGmatScore", String(e))}
            />
            <p className={`motivation ${!validData ? "active error-cls" : ""}`}>
              {!validData && "Enter a valid score"}
            </p>
          </Box>
        )}
      </Box>
      <Box className="bottom-buttons">
        <AppButton
          className="continue-button"
          onClick={backClick}
          customVariant="back"
          label="Back"
        />
        <AppButton
          className="continue-button"
          type="submit"
          onClick={handleSubmit}
          label="Continue"
        />
      </Box>
    </Box>
  );
};

export default Stepper3;
