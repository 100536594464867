import { Box } from "@mui/material";
// import { useState } from "react";
import "./styles.scss";
import AppButton from "../../AppButton";
// import TextBox from "../../TextBox";
import { useSelector } from "react-redux";
import axios from "axios";
import { useState } from "react";

const Password = () => {
  // const [newPass, setNewPass] = useState("");
  // const [newPass2, setNewPass2] = useState("");
  const [loading, setLoading] = useState(false)
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );

  const triggerPasswordReset = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "POST",
        url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        headers: {
          "content-type": "application/json",
        },
        data: {
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          email: userDetail.email,
          connection: "staging-pocketbud", // Your connection name (e.g., database)
        },
      });
      console.log("Password reset email sent:", response.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error(
        "Error triggering password reset:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <Box className="password-container">
      {/* <Box className="top-container">
        <TextBox
          preLabel={"New Password"}
          name={"Password"}
          variant="outlined"
          placeholder={"Enter your new password"}
          onChange={(e) => setNewPass(e.target.value)}
          value={newPass}
        />
        <TextBox
          preLabel="ReEnter New Password"
          placeholder={"ReEnter your New password"}
          variant="outlined"
          onChange={(e) => setNewPass2(e.target.value)}
          value={newPass2}
        />
      </Box> */}
      <Box className="btn-container">
        <AppButton
          label="Reset Password"
          onClick={triggerPasswordReset}
          className="save-btn"
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Password;
